@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';

/* You can add global styles to this file, and also import other style files */

@layer base {
  :root {
    --primary: 12 12 12;
    --surface-secondary: 41 41 41;
    --surface-brand: 59 229 127;
    --surface-invert: 255 255 255;
    --brand-green-12: 23 181 86;
    --text-primary: 245 245 245;
    --text-secondary: 153 153 153;
    --grey-3: 224 224 224;
    --grey-6: 194 194 194;
    --grey-8: 173 173 173;
    --grey-14: 112 112 112;
    --grey-16: 92 92 92;
    --grey-18: 71 71 71;
    --grey-20: 51 51 51;
    --grey-22: 41 41 41;
    --grey-23: 31 31 31;
    --grey-24: 20 20 20;
    --player-1: 125 83 222;
    --player-2: 255 190 11;
    --gradient-yellow1: 215 186 108;
    --gradient-yellow2: 157 119 20;
    --colors-green: 52 199 89;
    --green11: 53 206 114;
    --green12: 47 183 102;
    --green16: 39 90 59;
    --grass-green-10: 0 190 78;
    --grass-green-12: 0 158 66;
    --grass-green-14: 0 122 51;
    --grass-green-17: 0 61 26;
    --grass-green-gradient-1: 42 255 127;
    --grass-green-gradient-2: 7 217 94;
    --grass-green-gradient-3: 17 239 105;
    --grass-green-gradient-4: 4 188 80;
    --grass-green-gradient-5: 3 198 81;
    --grass-green-gradient-6: 3 155 65;
    --icon-size: 24px;
    --warning: 223 93 0;
    --danger: 255 49 46;
    --answer-wrong: 218 44 56;
    --statistics-blue: 35 143 215;
    --statistics-red: 221 49 90;
    --picture-frame: 103 105 108;
    --picture-frame-shadow: 68 69 71;
    --dark-blue: 40 45 57;
    --darker-blue: 25 30 42;
    --notification-red: 245 49 84;
    --notification-badge-text: 230 230 230;
    --card-green: 4 76 114;
    --score-background: 28 33 78;
    --card-grey: 211 211 211;
  }
}

html {
  // Disable overscroll / rubberbanding / pull-to-refresh
  overscroll-behavior: none;
}

html,
body {
  height: 100dvh;
  font-family: 'Oswald', sans-serif;
  background-color: rgb(var(--primary));
  //overscroll-behavior: none;
  overflow: hidden;

  &.lock-scroll {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh; /* Lock the height */
    overflow: hidden; /* Disable scrolling */
  }
}

.cdk-overlay-container {
  overflow: hidden;
  //-webkit-overflow-scrolling: touch;
}

h1, .h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
}

h2, .h2 {
  font-weight: 500;
  font-size: 24px;
}

h3, .h3 {
  font-weight: 500;
  font-size: 20px;
}

h4, .h4 {
  font-weight: 500;
  font-size: 16px;
}

h5, .h5 {
  font-size: 14px;
  font-weight: 500;
}

h6, .h6 {
  font-weight: 700;
  font-size: 12px;
}

h7, .h7 {
  font-weight: 500;
  font-size: 10px;
}

p {
  &.small {
    font-weight: 400;
    font-size: 12px;
  }

  &.medium {
    font-weight: 400;
    font-size: 16px;
  }

  &.large {
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
  }

  &.xxlarge {
    font-weight: 500;
    font-size: 40px;
    line-height: normal;
  }
}

.brand {
  font-family: 'Archivo', sans-serif;
  font-weight: 900;
  font-style: italic;
  letter-spacing: -0.32px;
}

.brand-normal {
  font-family: 'Archivo', sans-serif;
  font-weight: 900;
  font-style: italic;
  font-size: 13px;
  letter-spacing: -0.32px;
}

a {
  color: rgb(var(--surface-brand));
  text-decoration: none;
}

.overlay-backdrop {
  &.dark {
    background: rgba(0, 0, 0, .8);
  }

}

.mat-mdc-snack-bar-handset {
  z-index: 9999;
}

.mdc-snackbar__surface {
  box-shadow: none !important;
}

// Add smooth appearance for images
img {
  animation: fadeIn 0.25s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.in-game-modal-backdrop {
  @apply absolute inset-0 z-10 bg-black bg-opacity-50;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(var(--grey-18));
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--grey-18));
}

@media screen and (min-width: 600px) {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
}

.native-app * {
  *::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar {
  &:not(.show-scrollbar-only-desktop) {
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &.show-scrollbar-only-desktop {
    @media screen and (max-width: 601px) {
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.background-stripes {
  --internal-bright-stripe: rgb(var(--bright-stripe, 24 24 24));
  --internal-dark-stripe: rgb(var(--dark-stripe, var(--primary)));

  background: repeating-linear-gradient(
      -45deg,
      var(--internal-bright-stripe) 0px,
      var(--internal-bright-stripe) 10px,
      var(--internal-dark-stripe) 10px,
      var(--internal-dark-stripe) 20px,
      var(--internal-bright-stripe) 20px,
      var(--internal-bright-stripe) 30px,
      var(--internal-dark-stripe) 30px,
      var(--internal-dark-stripe) 40px,
      var(--internal-bright-stripe) 40px,
      var(--internal-bright-stripe) 110px,
      var(--internal-dark-stripe) 110px,
      var(--internal-dark-stripe) 130px,
      var(--internal-bright-stripe) 130px,
      var(--internal-bright-stripe) 160px,
      var(--internal-dark-stripe) 160px,
      var(--internal-dark-stripe) 170px,
      var(--internal-bright-stripe) 170px,
      var(--internal-bright-stripe) 190px,
      var(--internal-dark-stripe) 190px,
      var(--internal-dark-stripe) 230px,
      var(--internal-bright-stripe) 230px,
      var(--internal-bright-stripe) 300px,
      var(--internal-dark-stripe) 300px,
      var(--internal-dark-stripe) 340px,
      var(--internal-bright-stripe) 340px,
      var(--internal-bright-stripe) 365px,
      var(--internal-dark-stripe) 365px,
      var(--internal-dark-stripe) 395px,
      var(--internal-bright-stripe) 395px,
      var(--internal-bright-stripe) 405px,
      var(--internal-dark-stripe) 405px,
      var(--internal-dark-stripe) 415px,
      var(--internal-bright-stripe) 415px,
      var(--internal-bright-stripe) 445px,
      var(--internal-dark-stripe) 445px,
      var(--internal-dark-stripe) 495px
  );

  &.with-dark-gradient {
    position: relative;

    --internal-gradient-direction: var(--gradient-direction, 180deg);

    --gradient-color-internal-start-default: 0, 0, 0;
    --gradient-color-opacity_internal_end-default: 0.4;
    --gradient-color-opacity_internal_start-default: 0.0;
    --gradient-color-internal-start: var(--gradient-color-start, var(--gradient-color-internal-start-default));
    --gradient-color-internal-end: var(--gradient-color-end, var(--gradient-color-internal-start-default));
    --gradient-color-opacity_internal_start: var(--gradient-color-opacity_start, var(--gradient-color-opacity_internal_start-default));
    --gradient-color-opacity_internal_end: var(--gradient-color-opacity_end, var(--gradient-color-opacity_internal_end-default));

    --internal-gradient-start: rgba(var(--gradient-color-internal-start), var(--gradient-color-opacity_internal_start));
    --internal-gradient-end: rgba(var(--gradient-color-internal-end), var(--gradient-color-opacity_internal_end));

    &::before {
      @apply rounded-lg;
      content: '';
      pointer-events: none;
      position: absolute;
      opacity: var(--gradient-opacity, 1);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(var(--internal-gradient-direction, 0), var(--internal-gradient-start) 0%, var(--internal-gradient-end) 100%);
      transition: opacity 0.25s ease-in-out;
    }
  }
}

.click-smaller {
  &:active {
    transform: scale(0.98);
  }
}
